<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div v-if="user.data" class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.data.displayName }}
            </p>
            <span class="user-status">{{ userPlan }}</span>
            <span v-if="userPlan === 'Novice'" class="user-status">
              Used <strong :style="apiCalls >= 50 ? 'color:red' : ''">{{ apiCalls }}/50</strong> API calls
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.data ? user.data.photoURL : null"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider /> -->

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue';
import { collection, query, getDocs, limit } from '@firebase/firestore';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapGetters } from "vuex";
import { getAuth } from "firebase/auth";
import getUserPlan from "../../stripe/getUserPlan";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    userPlan: '-',
    apiCalls: '-'
  }),
	created() {
    setTimeout(() => {
      getUserPlan().then(async res => {
        this.userPlan = res;

        if (this.user.data) {
          if (this.userPlan === 'Novice') {
            const usage = await getDocs(query(
              collection(this.$firestore, "users", this.user.data.uid, "usage"),
              limit(50)
            ));
            this.apiCalls = usage.size;
          }
        }
        
      })
    }, 1000)
	},
  watch: {
    async "user.data.uid"() {
      if (this.userPlan === 'Novice') {
          const usage = await getDocs(query(
            collection(this.$firestore, "users", this.user.data.uid, "usage"),
            limit(50)
          ));
          this.apiCalls = usage.size;
        }
    }
  },
	filters: {
		capitalize: function (value) {
			if (!value) return ''
			value = value.toString()
			return value.charAt(0).toUpperCase() + value.slice(1)
		}
	},
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
	methods: {
		logout() {
			getAuth().signOut().then(() => {
				this.$router.replace({
					name: "login"
				});
			});
		}
	}
}
</script>
