export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Subscription',
    route: 'subscription',
    icon: 'CreditCardIcon',
  },
  {
    title: 'API Keys',
    route: 'api-keys',
    icon: 'KeyIcon',
  },
  {
    title: 'Scheduled Notifications',
    route: 'scheduled-notifications',
    icon: 'SendIcon',
  },
  {
    title: 'E-Mail Templates',
    route: 'email-templates',
    icon: 'MailIcon',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon'
  },
  {
    header: 'External Links',
  },
  {
    title: 'Documentation',
    href: 'https://docs.abracadalo.com',
    icon: 'ExternalLinkIcon',
  },
  {
    title: 'Roadmap',
    href: 'https://trello.com/b/GynL8nsR/abracadalo-roadmap',
    icon: 'TrelloIcon',
  },
]
